.new-users-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;
}
  
.new-user-row:nth-child(1) {
    color: black;
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}
  
.new-user-row:nth-child(2) {
    display: block;
    flex-grow: 3;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}
  
.new-user-row:nth-child(3) {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}

.invalid-checkbox {
    color: red !important;
}

.invalid-input {
    border: 5px !important;
    border-color: red !important;
    color: red !important;
    background: rgba(255, 0, 0, 0.425) !important;
}