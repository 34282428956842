body {
  background-image: url('Images/FriTwi_scott connor.jpg');
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position-y: bottom;
  background-position-x: right;
}

.mainTheme {
  background-color: transparent;
  height: 100%;
}

.App {
  text-align: center;
  height: 100%
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center {
  /* display: flex; */
  margin:auto;
  align-items: center;
  /* justify-content: center; */
}

.white-background {
  background-color: white;
}

.white-text {
  color: white;
}

.background {
  background-color: #003057 ;
}

.transparent {
  background-color: transparent;
}

.full-height {
  /* height: auto !important; */
  height:100vh;
}

.full-vertical-height {
  min-height: max-content;
  /* width: 100fr; */
}

.full-div-height {
  height: 100%
}

.register-screen-text {
  width: 100%;
  color: white;
  text-align: left;
  padding-left: 45px;
  padding-top: 25px;
  font-size: 15px;
}

.edit-button {
  margin-left: 20px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.flex-items:nth-child(1) {
  display: inline-block;
  width: 30%;
}

.flex-items:nth-child(2) {
  display: inline-block;
  width: 70%;
}

.divdisplay {
  display:-webkit-inline-box;
}
@media (orientation: portrait) {
  .flex-container-col {
    flex-direction: column;
  }

  body {
      background-image: none;
    background: #003057;
  }
}

.flex-container-row {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: normal;
  align-content: stretch;
}

.border {
  border: 10px transparent;
}

.flex-items-row:nth-child(1) {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.flex-items-row:nth-child(2) {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.flex-container-col {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: normal;
  align-content: stretch;
}

@media (orientation: portrait) {
  .flex-container-col {
      flex-direction: column;
  }

  .flex-items-col:nth-child(1) {
    display: none !important;
    /* flex-grow: 0;
    flex-shrink: 0; */
  }

  .flex-items-col:nth-child(2) {
    width: 100%;
    /* min-height: 10cm; */
  }

  body {
      background-image: none;
      background: #003057;
  }
}

@media (orientation: landscape) {
  .flex-items-col:nth-child(1) {
    display: block;
    flex-grow: 3;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
  }
}



.flex-items-col:nth-child(2) {
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 300px;
  align-self: center;
  order: 0;
  /* min-height: 100%; */
  background-color: #003057;
  padding-top: 100px;
  padding-bottom: 100px;

  /* margin: auto; */
}

.full-width {
  width: 100%;
}

.welcomeText{
  color: #FBFDFD;
  font-size: 34px;
  font-style: initial;
}

p {
  white-space: pre;
}

#line-break {
  white-space: pre-line;
}

.invalid-checkbox {
  color: red !important;
}

.invalid-input {
  border: 5px !important;
  border-color: red !important;
  color: red !important;
  background: rgba(255, 0, 0, 0.425) !important;
}

.bottom-right-align {
  vertical-align: bottom;
  align-self: flex-end;
  padding-right: 10px;
  padding-top: 0;
}

.top-left-align {
  padding-left: 5px;
  align-self: flex-start;
}

.left-justify{
  align-items:flex-start;
}

.link-style, .link-style:visited, .link-style:hover {
  color:white
}

.invisible {
  display: none;
}